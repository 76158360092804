import { Box, HStack, StackProps, Text } from '@chakra-ui/react';
import { useAuth } from 'hooks/authContext';
import * as React from 'react';
import { HiBadgeCheck } from 'react-icons/hi';
import { MdError } from 'react-icons/md';

export const VerifiedBadge = (props: StackProps) => {
  const { user } = useAuth();
  const userStatus = user.customer.subscription;
  return (
    <HStack
      color={userStatus === null ? 'gray.500' : '#FFD870'}
      minW='0'
      spacing='1'
      {...props}
    >
      <Box as={userStatus === null ? MdError : HiBadgeCheck} />
      <Text fontSize='sm' fontWeight='semibold' lineHeight='1'>
        {userStatus === null ? 'Inactive' : 'Premium'}
      </Text>
    </HStack>
  );
};
