import * as React from 'react';

import { Navbar } from '../components/Navbar';
import { Hero } from '../components/Hero';
import { Features } from '../components/Features';
import { FeatureOne } from '../components/FeatureOne';
import { Action } from '../components/Action';
import { Footer } from '../components/Footer';
import FAQPatreon from 'components/FAQPatreon';

export const LandingPage = () => (
  <>
    <Navbar />
    <Hero />
    <Features />
    <FeatureOne />
    <FAQPatreon />
    <Action />
    <Footer />
  </>
);
