import axios from 'axios';

const CREDENTIALS = {
  withCredentials: true,
};

export const getAuthStatus = () =>
  axios.get('http://104.236.113.116:3001/api/auth/status', CREDENTIALS);

export const sendPaymentMethod = (data) =>
  axios.post(
    'https://104.236.113.116:3001/api/payments/methods/create',
    data,
    CREDENTIALS
  );

export const deletePaymentMethod = (data) =>
  axios.post(
    'https://104.236.113.116:3001/api/payments/methods/delete',
    data,
    CREDENTIALS
  );

export const createSubscription = (data) =>
  axios.post(
    'https://104.236.113.116:3001/api/subscriptions/create',
    data,
    CREDENTIALS
  );

export const cancelSubscription = (data) =>
  axios.post(
    'https://104.236.113.116:3001/api/subscriptions/cancel',
    data,
    CREDENTIALS
  );
