import * as React from 'react';
import {
  HiOutlineCreditCard,
  HiOutlineShoppingCart,
  HiOutlineChat,
} from 'react-icons/hi';
import { Box, Button, Icon } from '@chakra-ui/react';
import { List } from './List';
import { ListItem } from './ListItem';
import { StepOne } from './StepOne';
import { useAuth } from 'hooks/authContext';
import StepTwo from './StepTwo';

export const AccountFlow = () => {
  const { user } = useAuth();

  return (
    <Box as='section' minHeight='100vh' marginBottom='100px'>
      <Box maxW='7xl' mx='auto' p='4'>
        <List spacing='12'>
          {user && user.customer.defaultPaymentId !== null ? (
            <ListItem
              title='Payment is successfully added.'
              subTitle='You may edit or remove your card at anytime.'
              icon={<Icon as={HiOutlineCreditCard} boxSize='6' />}
            />
          ) : (
            <ListItem
              title='1. Add Your Payment Method'
              subTitle='Payment methods are secured and handled through Stripe.'
              icon={<Icon as={HiOutlineCreditCard} boxSize='6' />}
            >
              <StepOne />
            </ListItem>
          )}
          {user && user.customer.subscription === 'active' ? (
            <ListItem
              title='Hooray! Your subscription is currently active.'
              subTitle='You may cancel at anytime to stop future payments.'
              icon={<Icon as={HiOutlineShoppingCart} boxSize='6' />}
            />
          ) : (
            <ListItem
              title='2. Select Your Subscription'
              subTitle='Subscriptions are charged monthly and can be canceled anytime.'
              icon={<Icon as={HiOutlineShoppingCart} boxSize='6' />}
            >
              <StepTwo />
            </ListItem>
          )}
          {user && user.customer.subscription === null ? (
            <ListItem
              title='Purchase A Subscription To Gain Access'
              subTitle='Complete step one and two.'
              icon={<Icon as={HiOutlineChat} boxSize='6' />}
            >
              <Box maxW='300px' width='full'>
                <Button
                  w='full'
                  isDisabled={user.customer.subscription === null}
                  bg='#58b4d9'
                  color='white'
                  _hover={{ bg: false }}
                >
                  Join Discord Server
                </Button>
              </Box>
            </ListItem>
          ) : (
            <ListItem
              title='Welcome To The Island!'
              subTitle='Join the Discord by hitting the button below.'
              icon={<Icon as={HiOutlineChat} boxSize='6' />}
            >
              <Box maxW='300px' width='100%'>
                <Button
                  as='a'
                  href='https://discord.gg/HE6PssPBFa'
                  target='_blank'
                  rel='noopener noreferrer'
                  w='full'
                  bg='#58b4d9'
                  color='white'
                  _hover={{ bg: false }}
                >
                  Join Discord Server
                </Button>
              </Box>
            </ListItem>
          )}
        </List>
      </Box>
    </Box>
  );
};
