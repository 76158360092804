import { ButtonGroup, ButtonGroupProps, IconButton } from '@chakra-ui/react';
import * as React from 'react';
import { FaInstagram } from 'react-icons/fa';

export const SocialMediaLinks = (props: ButtonGroupProps) => (
  <ButtonGroup variant='ghost' color='#58b4d9' {...props}>
    <IconButton
      as='a'
      href='http://www.instagram.com/paradiseinvesting'
      aria-label='Instagram'
      icon={<FaInstagram fontSize='20px' />}
    />
  </ButtonGroup>
);
