import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Text,
} from '@chakra-ui/react';

import { useAuth } from 'hooks/authContext';
import { cancelSubscription } from 'utils/api';

const CancelSubscription = () => {
  const { user, setUser } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        onClick={onOpen}
        colorScheme='red'
        isDisabled={
          !(
            user.customer.subscription === 'active' &&
            user.customer.expiringEom === null
          )
        }
      >
        Cancel Plan
      </Button>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        trapFocus={false}
        isCentered
        closeOnOverlayClick
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Cancellation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Text>
                Please confirm that you would like to cancel your subscription.
              </Text>
              <Text paddingTop='20px'>
                You subscription and access to the island will stay active until
                the end of your billing cycle. You will no longer be charged.
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme='red'
              onClick={() =>
                cancelSubscription()
                  .then(({ data }) => setUser(data))
                  .then(() => onClose())
                  .catch((err) => console.log(err))
              }
            >
              Cancel Subscription
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CancelSubscription;
