import * as React from 'react';
import { animateScroll as scroll } from 'react-scroll';

import { Login } from 'components/Login';
import { Box } from '@chakra-ui/react';

export const LoginPage = () => {
  React.useEffect(() => {
    scroll.scrollToTop();
  }, []);

  return (
    <Box>
      <Login />
    </Box>
  );
};
