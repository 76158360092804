import {
  Box,
  Link,
  SimpleGrid,
  SimpleGridProps,
  Stack,
} from '@chakra-ui/react';
import * as React from 'react';
import { Link as LinkScroll } from 'react-scroll';

import { FooterHeading } from './FooterHeading';

export const LinkGrid = (props: SimpleGridProps) => (
  <SimpleGrid columns={3} {...props} flexWrap="wrap" marginX="10px">
    <Box minW="80px">
      <FooterHeading mb="4" fontWeight="extrabold" color="#58b4d9">
        Navigate
      </FooterHeading>
      <Stack>
        <LinkScroll
          to="about"
          smooth={true}
          duration={500}
          spy={true}
          offset={-100}
          style={{
            cursor: 'pointer',
            fontWeight: 'bold',
          }}
        >
          About
        </LinkScroll>
        <LinkScroll
          to="features"
          smooth={true}
          duration={500}
          spy={true}
          offset={-40}
          style={{
            cursor: 'pointer',
            fontWeight: 'bold',
          }}
        >
          Features
        </LinkScroll>
        {/* <LinkScroll
          to='pricing'
          smooth={true}
          duration={500}
          spy={true}
          offset={-40}
          style={{
            cursor: 'pointer',
            fontWeight: 'bold',
          }}
        >
          Pricing
        </LinkScroll> */}
        <LinkScroll
          to="faq"
          smooth={true}
          duration={500}
          spy={true}
          offset={-40}
          style={{
            cursor: 'pointer',
            fontWeight: 'bold',
          }}
        >
          FAQ
        </LinkScroll>
      </Stack>
    </Box>
    <Box minW="80px">
      <FooterHeading mb="4" fontWeight="extrabold" color="#58b4d9">
        Legal
      </FooterHeading>
      <Stack>
        {/* <Link
          href="https://app.termly.io/document/privacy-policy/375af2f0-1ebc-4b87-8ca1-bae9ebed469b"
          fontWeight="bold"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy
        </Link> */}
        <Link
          to="privacy"
          fontWeight="bold"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy
        </Link>
        <Link
          href="https://app.termly.io/document/terms-of-use-for-website/e4964960-5148-4a39-b2eb-b4786dfeac27"
          fontWeight="bold"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms
        </Link>
      </Stack>
    </Box>
    <Box minW="80px">
      <FooterHeading mb="4" fontWeight="extrabold" color="#58b4d9">
        Contact
      </FooterHeading>
      <Stack>
        <Link
          href="mailto:laviantllc@gmail.com"
          fontWeight="bold"
          target="_blank"
          rel="noopener noreferrer"
        >
          Email
        </Link>
        <Link
          href="http://www.instagram.com/paradiseinvesting"
          target="_blank"
          rel="noopener noreferrer"
          fontWeight="bold"
        >
          Instagram
        </Link>
      </Stack>
    </Box>
  </SimpleGrid>
);
