import * as React from 'react';
import { Box, HStack, Img, Stack, Text } from '@chakra-ui/react';
import { HiOutlineCalendar } from 'react-icons/hi';

import { useAuth } from 'hooks/authContext';
import { TextWithIcon } from './TextWithIcon';
import { VerifiedBadge } from './VerifiedBadge';
import Logo from '../../images/logo-dark.svg';
import CancelSubscription from './CancelSubscription';
import { AccountBanner } from 'components/AccountBanner';
import RemovePayment from './RemovePayment';

export const AccountHeader = () => {
  const { user } = useAuth();

  return (
    user && (
      <>
        {user.customer.expiringEom === true &&
          user.customer.subscription === 'active' && <AccountBanner />}
        <Box p='8'>
          <Box maxW='7xl' mx='auto'>
            <Stack
              spacing={{ base: '8', md: '4' }}
              direction={{ base: 'column', lg: 'row' }}
              justify='space-between'
              align='flex-start'
            >
              <Stack
                direction='row'
                flex='1'
                spacing='6'
                align={{ base: 'flex-start', md: 'center' }}
              >
                <Img
                  src={Logo}
                  alt='Avatar'
                  objectFit='cover'
                  rounded='full'
                  h='20'
                  w='20'
                />

                <Box flex='1'>
                  <HStack mb={{ base: '3', md: '1' }}>
                    <Text fontSize='xl' fontWeight='bold'>
                      Hey {user.username}.
                    </Text>
                  </HStack>
                  <Stack
                    direction={{ base: 'column', md: 'row' }}
                    spacing={{ base: '3', lg: '6' }}
                  >
                    <VerifiedBadge />
                    <TextWithIcon icon={<HiOutlineCalendar />}>
                      Joined {user.joinDate}
                    </TextWithIcon>
                  </Stack>
                </Box>
              </Stack>
              <Box flex='1'>
                <HStack spacing='4'>
                  <RemovePayment />
                  <CancelSubscription />
                </HStack>
              </Box>
            </Stack>
          </Box>
        </Box>
      </>
    )
  );
};
