import { RiErrorWarningFill } from 'react-icons/ri';
import { Box, Stack, HStack, Text, Icon } from '@chakra-ui/react';
import * as React from 'react';

export const AccountBanner = () => (
  <Box as='section' pb='4'>
    <Stack
      direction={{ base: 'column', sm: 'row' }}
      py='3'
      px={{ base: '3', md: '6', lg: '8' }}
      color='white'
      bg='orange.500'
      justifyContent='center'
      alignItems='center'
    >
      <HStack direction='row' spacing='3'>
        <Icon as={RiErrorWarningFill} boxSize='6' />
        <Text fontWeight='medium' marginEnd='2'>
          Your plan is set to expire at the end of your billing cycle. You will
          no longer be charged.
        </Text>
      </HStack>
    </Stack>
  </Box>
);
