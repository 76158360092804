import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
} from '@chakra-ui/react';

import { useAuth } from 'hooks/authContext';
import { deletePaymentMethod } from 'utils/api';

const RemovePayment = () => {
  const { user, setUser } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        onClick={onOpen}
        isDisabled={user.customer.defaultPaymentId === null}
      >
        Edit Payment
      </Button>

      <Modal
        onClose={onClose}
        isOpen={isOpen}
        trapFocus={false}
        isCentered
        closeOnOverlayClick
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Card</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              To edit your payment method, you must first remove your existing
              one. Please confirm the removal of your existing payment method.
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme='red'
              onClick={() =>
                deletePaymentMethod()
                  .then(({ data }) => setUser(data))
                  .then(() => onClose())
                  .catch((err) => console.log(err))
              }
            >
              Remove Payment Method
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RemovePayment;
