import { Box, SimpleGrid } from '@chakra-ui/react';
import * as React from 'react';

import { ReactComponent as CommunityIcon } from 'images/icons/community.svg';
import { ReactComponent as EducationIcon } from 'images/icons/education.svg';
import { ReactComponent as NotificationIcon } from 'images/icons/notification.svg';
import { Feature } from './Feature';

export const Features = () => {
  return (
    <Box as='section' id='features' py='24'>
      <Box
        maxW={{ base: 'xl', md: '5xl' }}
        mx='auto'
        px={{ base: '6', md: '8' }}
      >
        <SimpleGrid columns={{ base: 1, md: 3 }} spacingX='10' spacingY='14'>
          <Feature title='Daily Notifications' icon={<NotificationIcon />}>
            With daily notifications, getting informed about stocks that are
            moving has never been easier.
          </Feature>
          <Feature title='Learn Together' icon={<EducationIcon />}>
            Members are encouraged to ask questions and start discussions on all
            market topics.
          </Feature>
          <Feature title='Community' icon={<CommunityIcon />}>
            Paradise Investing is open 24/7. Conversations are never limited to
            just market hours.
          </Feature>
        </SimpleGrid>
      </Box>
    </Box>
  );
};
