import React from 'react';
import { Box, Spinner } from '@chakra-ui/react';

import { useAuth } from 'hooks/authContext';
import ConfirmTen from './ConfirmTen';
import ConfirmThirty from './ConfirmThirty';

const StepTwo = () => {
  const { user } = useAuth();

  if (!user) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        maxWidth='300px'
        width='100%'
      >
        <Spinner color='#58b4d9' />
      </Box>
    );
  }

  return (
    <Box w='full'>
      <ConfirmTen />
      <Box paddingTop='20px'>
        <ConfirmThirty />
      </Box>
    </Box>
  );
};

export default StepTwo;
