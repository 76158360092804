import { Box, Heading, Text } from '@chakra-ui/react';
import * as React from 'react';

export const Action = () => {
  return (
    <Box as='section' paddingY='40px'>
      <Box
        maxW='5xl'
        mx='auto'
        px={{ base: '6', lg: '8' }}
        py={{ base: '16', sm: '20' }}
        textAlign='center'
      >
        <Heading
          as='h2'
          size='2xl'
          fontWeight='extrabold'
          letterSpacing='tight'
          paddingBottom='20px'
        >
          Disclaimer
        </Heading>
        <Text mt='4' fontSize='lg'>
          The information provided by Paradise Investing members and users on
          our subscription based Discord server is for general informational
          purposes only. We are not financial advisors. All information on the
          site and Discord server is provided in good faith, however we make no
          representation or warranty of any kind, express or implied, regarding
          the accuracy, adequacy, validity, reliability, availability, or
          completeness of any information on the Discord. Under no circumstances
          shall we have any liability to you for any loss or damage incurred as
          a result of the use of the site or Discord server or reliance on any
          information provided on the Discord server. Your use of and reliance
          on any information found, read, or discussed on this website or
          Discord server is solely at your own risk.
        </Text>
      </Box>
    </Box>
  );
};
