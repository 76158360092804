import { HTMLChakraProps } from '@chakra-ui/react';
import * as React from 'react';

export const Logo = (
  props: HTMLChakraProps<'svg'> & { iconColor?: string }
) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      viewBox='0 0 179.07 179.07'
    >
      <path
        d='M121.45,175.66C106.14,163,97.71,146.05,97.71,128v-8.73h7.46V128c0,15.77,7.47,30.66,21,41.89Z'
        transform='translate(-60.41 -60.87)'
        fill='#495057'
      />
      <path
        d='M194.71,97.31v4.19c0,26.41-11,51.22-31,69.88l-5.09-5.46c18.48-17.23,28.66-40.1,28.66-64.42V96.9'
        transform='translate(-60.41 -60.87)'
        fill='#495057'
      />
      <path
        d='M209.63,87c7.46,0,23.39,2.21,29.85,7.46-4.85-14.54-15.3-22.38-29.85-22.38,0-3.73,3.73-7.46,14.92-7.46-3.73-3.73-11.19-3.73-14.92-3.73A28.49,28.49,0,0,0,191,68.33a28.5,28.5,0,0,0-18.66-7.46c-3.73,0-11.19,0-14.92,3.73,11.19,0,14.92,3.73,14.92,7.46-14.54,0-25,7.84-29.84,22.38,6.45-5.25,22.38-7.46,29.84-7.46-14.54,9.69-14.92,26.11-14.92,41,4.45-8.89,14.79-26.54,29.71-30.27a13.32,13.32,0,0,1,3.87-.59,19,19,0,0,1,3.85.57c14.92,3.73,25.28,21.4,29.72,30.29C224.55,113.09,224.18,96.67,209.63,87Z'
        transform='translate(-60.41 -60.87)'
        fill='#8fdef6'
      />
      <ellipse cx='89.53' cy='141.76' rx='70.88' ry='37.31' fill='#fece66' />
      <path
        d='M239.48,195.17v44.77H60.41V195.17A17.84,17.84,0,0,1,71.6,198.9a18.65,18.65,0,0,0,22.38,0,18.67,18.67,0,0,1,22.39,0,18.65,18.65,0,0,0,22.38,0,18.65,18.65,0,0,1,22.38,0,18.67,18.67,0,0,0,22.39,0,18.65,18.65,0,0,1,22.38,0,18.65,18.65,0,0,0,22.38,0A17.84,17.84,0,0,1,239.48,195.17Z'
        transform='translate(-60.41 -60.87)'
        fill='#58b4d9'
      />
      <path
        d='M239.48,213.82v26.12H60.41V213.82a17.84,17.84,0,0,1,11.19,3.73,18.65,18.65,0,0,0,22.38,0,18.67,18.67,0,0,1,22.39,0,18.65,18.65,0,0,0,22.38,0,18.65,18.65,0,0,1,22.38,0,18.67,18.67,0,0,0,22.39,0,18.65,18.65,0,0,1,22.38,0,18.65,18.65,0,0,0,22.38,0A17.84,17.84,0,0,1,239.48,213.82Z'
        transform='translate(-60.41 -60.87)'
        fill='#8fdef6'
      />
      <path
        d='M93.54,122.25c.66-.33,1.43-.69,2.23-1-.74.3-1.49.65-2.23,1Z'
        transform='translate(-60.41 -60.87)'
        fill='none'
      />
      <path
        d='M110.61,123a23.29,23.29,0,0,0-4.66-2.18A33.29,33.29,0,0,1,110.61,123Z'
        transform='translate(-60.41 -60.87)'
        fill='none'
      />
      <path
        d='M120.1,113.09c11.19,0,16.91,3,22.38,7.47-3.73-14.93-13.8-22.39-26.11-22.39,0-3.73,7.46-7.46,14.92-7.46C127.56,87,120.1,87,116.37,87c-7.47,0-14.93,3.73-14.93,7.46C101.44,90.71,94,87,86.52,87c-3.73,0-11.19,0-14.92,3.73,7.46,0,14.92,3.73,14.92,7.46-12.31,0-22.38,7.46-26.11,22.39,5.46-4.45,11.19-7.47,22.38-7.47C71.6,120.56,71.6,131.75,71.6,146.67c3.22-6.44,12.25-19.27,21.94-24.42.74-.39,1.49-.74,2.23-1a15.32,15.32,0,0,1,5.67-1.28,13.5,13.5,0,0,1,4.51.86,23.29,23.29,0,0,1,4.66,2.18c9.2,5.55,17.6,17.54,20.68,23.7C131.29,131.75,131.29,120.56,120.1,113.09Z'
        transform='translate(-60.41 -60.87)'
        fill='#8fdef6'
      />
    </svg>
  );
};
