import React, { useState, useEffect, useContext, createContext } from 'react';
import { getAuthStatus } from 'utils/api';

const userContext = createContext();

export const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();
  return <userContext.Provider value={auth}>{children}</userContext.Provider>;
};

export const useAuth = () => {
  return useContext(userContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    let isDone = false;
    getAuthStatus()
      .then(({ data }) => {
        if (!isDone) setUser(data);
      })
      .catch((err) => {});

    return () => {
      isDone = true;
    };
  }, []);

  return { user, setUser };
}
