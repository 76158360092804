import {
  Box,
  Button,
  Flex,
  HStack,
  useColorModeValue as mode,
  VisuallyHidden,
} from '@chakra-ui/react';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Logo } from './Logo';
import { MobileNav } from './MobileNav';

import { Link as LinkScroll } from 'react-scroll';
import { animateScroll as scroll } from 'react-scroll';

import styles from './index.module.css';

export const Navbar = () => {
  const [offset, setOffset] = useState(0);

  const handleScroll = () => {
    setOffset(window.pageYOffset);
  };

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollStyles =
    offset === 0
      ? {}
      : {
          boxShadow:
            '0 0 1px rgba(67,90,111,.47), 0 2px 4px -2px rgba(67,90,111,.3)',
        };

  return (
    <Box minH="80px">
      <Box
        as="header"
        bg={mode('white', 'gray.900')}
        className={styles.landingStyles}
        style={scrollStyles}
      >
        <Box maxW="7xl" mx="auto" py="4" px={{ base: '6', md: '8' }}>
          <Flex as="nav" justify="space-between">
            <HStack spacing="8">
              <Link to="/">
                <Box onClick={toggleHome}>
                  <VisuallyHidden>Paradise Investing</VisuallyHidden>
                  <Logo h="10" iconColor="blue.500" />
                </Box>
              </Link>
            </HStack>
            <Flex align="center">
              <HStack display={{ base: 'none', lg: 'flex' }} spacing="8" mr="8">
                <LinkScroll
                  to="about"
                  smooth={true}
                  duration={500}
                  spy={true}
                  offset={-100}
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    color: '#343f43',
                  }}
                >
                  About
                </LinkScroll>
                <LinkScroll
                  to="features"
                  smooth={true}
                  duration={500}
                  spy={true}
                  offset={-40}
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    color: '#343f43',
                  }}
                >
                  Features
                </LinkScroll>
                {/* <LinkScroll
                  to='pricing'
                  smooth={true}
                  duration={500}
                  spy={true}
                  offset={-40}
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    color: '#343f43',
                  }}
                >
                  Pricing
                </LinkScroll> */}
                <LinkScroll
                  to="faq"
                  smooth={true}
                  duration={500}
                  spy={true}
                  offset={-40}
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    color: '#343f43',
                  }}
                >
                  FAQ
                </LinkScroll>
              </HStack>
              <HStack display={{ base: 'none', md: 'flex' }}>
                <Button
                  as="a"
                  href="https://www.patreon.com/paradiseinvesting"
                  target="_blank"
                  rel="noopener noreferrer"
                  bg="#58b4d9"
                  color="white"
                  _hover={{ bg: '#4c9bbb' }}
                >
                  Get Started
                </Button>
              </HStack>
              {/* {!user ? (
                <HStack display={{ base: 'none', md: 'flex' }}>
                  <Link to='/login'>
                    <Button
                      bg='#58b4d9'
                      color='white'
                      _hover={{ bg: '#4c9bbb' }}
                    >
                      Get Started
                    </Button>
                  </Link>
                </HStack>
              ) : (
                <HStack display={{ base: 'none', md: 'flex' }}>
                  <Link to='/account'>
                    <Button
                      bg='#58b4d9'
                      color='white'
                      _hover={{ bg: '#4c9bbb' }}
                    >
                      Manage Account
                    </Button>
                  </Link>
                </HStack>
              )} */}
              <Box ml="5">
                <MobileNav />
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};
