import { Flex, Box, Heading, Text, Stack, VStack } from '@chakra-ui/react';
import * as React from 'react';

import { ReactComponent as HeroImage } from 'images/hero.svg';

export const Hero = () => {
  return (
    <>
      <Box>
        <Flex paddingTop='80px' paddingBottom='80px'>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            textAlign='center'
            w='full'
          >
            <Heading as='h1' size='2xl' px='8px' fontWeight='extrabold'>
              Welcome to Paradise Investing
            </Heading>
          </Box>
        </Flex>
        <Box width='100%' margin='0' padding='0'>
          <HeroImage />
        </Box>

        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          w='full'
          pt='8'
          pb='8'
        >
          <Stack
            spacing={{ base: '8', lg: '40' }}
            direction={{ base: 'column-reverse', lg: 'row' }}
            px={{ base: '6', md: '8' }}
          >
            <Flex maxW='4xl' alignItems='center'>
              <VStack alignItems='center'>
                <Heading
                  as='h1'
                  size='2xl'
                  mb='2'
                  fontWeight='extrabold'
                  id='about'
                >
                  About Us
                </Heading>
                <Text fontSize='lg' m='16' color='gray.600' textAlign='center'>
                  Paradise Investing is an online Discord community that focuses
                  on helping members get a better understanding of the stock
                  market. Our community is an open space for asking questions
                  and sharing thoughts and opinions with others.
                </Text>
                <br />
                <Text fontSize='lg' m='16' color='gray.600' textAlign='center'>
                  Whether you're new, experienced, or diving back in, joining
                  our island will give you the opportunity to collaborate with
                  passionate investors and traders!
                </Text>
              </VStack>
            </Flex>
          </Stack>
        </Box>
      </Box>
    </>
  );
};
