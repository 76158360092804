import * as React from 'react';

import { animateScroll as scroll } from 'react-scroll';

import { AccountHeader } from 'components/AccountHeader';
import { NavbarAccount } from '../components/NavbarAccount';
import { AccountFlow } from '../components/AccountFlow';

import { FooterAccount } from 'components/FooterAccount';

export const AccountPage = () => {
  // const { user } = useAuth();

  React.useEffect(() => {
    scroll.scrollToTop();
  }, []);

  // if (!user) {
  //   history.push('/');
  // }
  return (
    <>
      <NavbarAccount />
      <AccountHeader />
      <AccountFlow />
      <FooterAccount />
    </>
  );
};
