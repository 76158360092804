import * as React from 'react';
import { Navbar } from 'components/Navbar';
import { Disclaimer } from 'components/Disclaimer';

import { Footer } from 'components/Footer';

export const DisclaimerPage = () => {
  return (
    <>
      <Navbar />
      <Disclaimer />
      <Footer />
    </>
  );
};
