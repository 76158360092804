import {
  Box,
  Center,
  Heading,
  Img,
  VStack,
  Text,
  Flex,
  Stack,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import * as React from 'react';

import F1Image from 'images/f1.png';
import { FeatureTwoIcon } from 'images/Icon2';
import { FeatureThreeIcon } from 'images/Icon3';

export const FeatureOne = () => {
  return (
    <Box as='section' paddingBottom='20'>
      <Box
        maxW={{ base: 'xl', md: '7xl' }}
        mx='auto'
        px={{ base: '6', md: '8' }}
      >
        <VStack spacing={{ base: '4', lg: '20' }}>
          <Stack
            spacing={{ base: '4', lg: '20' }}
            direction={{ base: 'column', lg: 'row' }}
          >
            <Center flex='1' minH='26rem' maxW={{ lg: 'xl' }}>
              <Img
                objectFit='cover'
                w='full'
                h='full'
                htmlWidth='576px'
                htmlHeight='420px'
                src={F1Image}
              />
            </Center>
            <Flex maxW={{ lg: 'lg' }} alignItems='center'>
              <VStack alignItems='flex-start'>
                <Heading
                  size='10xl'
                  mt='10'
                  fontWeight='extrabold'
                  letterSpacing='0.0625em'
                  lineHeight='normal'
                  color='#58b4d9'
                  textTransform='uppercase'
                >
                  Daily Notifications
                </Heading>
                <Heading
                  size='2xl'
                  fontWeight='extrabold'
                  letterSpacing='tight'
                  lineHeight='normal'
                >
                  Stock Alerts
                </Heading>
                <Text fontSize='lg' mt='6' color={mode('gray.600', 'gray.400')}>
                  Once you join and gain access to our Discord, you will
                  automatically be subscribed to our top daily stock
                  notifications. These notifications help you get updated
                  quickly so you can start planning out your next play!
                </Text>
              </VStack>
            </Flex>
          </Stack>

          <Stack
            spacing={{ base: '4', lg: '20' }}
            direction={{ base: 'column-reverse', lg: 'row' }}
          >
            <Flex maxW={{ lg: 'lg' }} alignItems='center'>
              <VStack alignItems='flex-start'>
                <Heading
                  size='10xl'
                  mt='10'
                  fontWeight='extrabold'
                  letterSpacing='0.0625em'
                  lineHeight='normal'
                  color='#58b4d9'
                  textTransform='uppercase'
                >
                  Education
                </Heading>
                <Heading
                  size='2xl'
                  fontWeight='extrabold'
                  letterSpacing='tight'
                  lineHeight='normal'
                >
                  Learn Together
                </Heading>
                <Text fontSize='lg' mt='6' color={mode('gray.600', 'gray.400')}>
                  Getting started in the stock market can be overwhelming. Our
                  community is beginner friendly and we encourage users that are
                  new to ask questions. There are many experienced investors in
                  our community that are willing to help out and answer any
                  questions you may have.
                </Text>
              </VStack>
            </Flex>
            <Center flex='1' minH='26rem' maxW={{ lg: 'xl' }}>
              <FeatureTwoIcon
                boxSize={{ base: 296, lg: 600 }}
                width='full'
                height='full'
              />
            </Center>
          </Stack>
          <Stack
            spacing={{ base: '4', lg: '20' }}
            direction={{ base: 'column', lg: 'row' }}
          >
            <Center
              flex='1'
              minH='26rem'
              maxW={{ base: 'xl', lg: '7xl' }}
              justify='center'
              alignItems='center'
            >
              <FeatureThreeIcon
                boxSize={{ base: 296, lg: 600 }}
                width='full'
                height='full'
              />
            </Center>
            <Flex maxW={{ lg: 'lg' }} alignItems='center'>
              <VStack alignItems='flex-start'>
                <Heading
                  size='10xl'
                  mt='10'
                  fontWeight='extrabold'
                  letterSpacing='0.0625em'
                  lineHeight='normal'
                  color='#58b4d9'
                  textTransform='uppercase'
                >
                  Community
                </Heading>
                <Heading
                  size='2xl'
                  fontWeight='extrabold'
                  letterSpacing='tight'
                  lineHeight='normal'
                >
                  Full Access
                </Heading>
                <Text fontSize='lg' mt='6' color={mode('gray.600', 'gray.400')}>
                  Paradise Investing is full of investors and traders who are
                  eager to learn just like you. Whenever you have a question,
                  need clarification, or are inclined to share your due
                  dilligence, our community is open 24/7. Any future updates
                  will also be included in all active memberships at no
                  additional cost.
                </Text>
              </VStack>
            </Flex>
          </Stack>
        </VStack>
      </Box>
    </Box>
  );
};
