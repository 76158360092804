import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Text,
  Spinner,
} from '@chakra-ui/react';

import { useAuth } from 'hooks/authContext';
import { createSubscription } from 'utils/api';

const ConfirmThirty = () => {
  const { user, setUser } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [fetching, setFetching] = useState(false);

  const priceId = {
    thirtyMonthly: `${process.env.REACT_APP_PRODUCT_THIRTY}`,
  };

  const handleSubscription = async () => {
    setFetching(true);
    const { data } = await createSubscription({
      priceId: priceId.thirtyMonthly,
    });
    setFetching(false);
    setUser(data);
  };

  return (
    <>
      <Box maxW='300px' w='full'>
        <Button
          onClick={onOpen}
          disabled={fetching | (user.customer.defaultPaymentId === null)}
          w='full'
          bg='#58b4d9'
          color='white'
          _hover={{ bg: false }}
        >
          Subscribe $30 / Month
        </Button>
      </Box>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        trapFocus={false}
        isCentered
        closeOnOverlayClick
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Monthly $30 Subscription</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Text>Please confirm your subscripton.</Text>
              <Text paddingTop='20px'>
                Your payment method will be charged and your subscription will
                become active. You may visit your account page to manage your
                subscription in the future.
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => handleSubscription().then(() => onClose())}
              disabled={fetching | (user.customer.defaultPaymentId === null)}
              bg='#58b4d9'
              color='white'
              _hover={{ bg: false }}
            >
              {fetching ? <Spinner color='#fff' /> : 'Subscribe'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmThirty;
