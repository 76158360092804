import {
  Box,
  Button,
  Heading,
  Img,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import * as React from 'react';

import IslandTreasure from 'images/island-treasure.png';
import { FaDiscord } from 'react-icons/fa';
import { NavbarAccount } from 'components/NavbarAccount';
import { FooterAccount } from 'components/FooterAccount';

export const Login = () => {
  return (
    <Box>
      <NavbarAccount />
      <Box
        bg={mode('gray.50', 'inherit')}
        py='12'
        px={{ sm: '6', lg: '8' }}
        minH='100vh'
      >
        <Box maxW={{ sm: 'md' }} mx={{ sm: 'auto' }} w={{ sm: 'full' }}>
          <Heading mt='6' textAlign='center' size='xl' fontWeight='extrabold'>
            Login
          </Heading>
        </Box>
        <Box maxW={{ sm: 'md' }} mx={{ sm: 'auto' }} mt='8' w={{ sm: 'full' }}>
          <Box
            minH='200px'
            display='flex'
            justifyContent='center'
            alignItems='center'
            bg={mode('white', 'gray.700')}
            px={{ base: '4', md: '10' }}
            shadow='base'
            rounded={{ sm: 'lg' }}
          >
            <Box w='full'>
              <Button
                as='a'
                color='white'
                background='#7289da'
                variant=' outline'
                w='full'
                href='http://104.236.113.116:3001/api/auth/discord'
              >
                <FaDiscord />
                <Text pl='10px'>Login With Discord</Text>
              </Button>
            </Box>
          </Box>
          <Box mt='4' align='center' maxW='md' fontWeight='medium'>
            <span>Don&apos;t have a Discord account?</span>
            <Text
              as='a'
              marginStart='1'
              href='https://www.discord.com'
              target='_blank'
              rel='noopener noreferrer'
              color={mode('blue.600', 'blue.200')}
              _hover={{ color: 'blue.600' }}
              display={{ base: 'block', sm: 'revert' }}
            >
              Create One For Free
            </Text>
            <Text
              flex='1'
              minH='20rem'
              paddingTop='100px'
              maxW={{ base: '400', lg: 'xl' }}
            >
              <Img w='full' h='full' src={IslandTreasure} />
            </Text>
          </Box>
        </Box>
      </Box>
      <FooterAccount />
    </Box>
  );
};
