import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Heading,
} from '@chakra-ui/react';
import * as React from 'react';

const FAQPatreon = () => {
  return (
    <Box as="section" id="faq" p="24px" maxW="4xl" mx="auto" paddingTop="40px">
      <Heading
        as="h2"
        size="2xl"
        fontWeight="extrabold"
        letterSpacing="tight"
        lineHeight="normal"
        paddingY="20px"
        paddingLeft="14px"
      >
        FAQs
      </Heading>
      <Accordion>
        <AccordionItem
          _expanded={{
            border: '1px solid #f5f5f5',
            borderRadius: '8px',
          }}
        >
          <h2>
            <AccordionButton
              _expanded={{
                border: '1px solid #f5f5f5',
                borderRadius: '8px',
              }}
            >
              <Box flex="1" textAlign="left" fontWeight="medium">
                What is Discord?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} maxWidth="3xl">
            Discord is an application that allows users to create servers and
            chat rooms for other users to join and talk in. For more information
            on how to create a free Discord account vist www.discord.com
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="medium">
                How do I get access?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} maxWidth="3xl">
            To gain access to Paradise Investing click the "Get Started" button
            in the navigation bar. This will forward you to our Discord invite
            page.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="medium">
                I'm having some trouble getting access, can someone help me?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} maxWidth="3xl">
            If you're having any issues getting set up feel free to reach out to
            us via email at support@paradiseinvesting.io for more assistance or
            Instagram @ParadiseInvesting
          </AccordionPanel>
        </AccordionItem>

        {/* <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="medium">
                How do I manage my subscription?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} maxWidth="3xl">
            To manage your subscription visit your Patreon account page. From
            there you will be able to remove or edit payment methods and cancel
            your subscription.
          </AccordionPanel>
        </AccordionItem> */}

        {/* <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="medium">
                What is your refund policy?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} maxWidth="3xl">
            We currently do not offer refunds. If you currently have an active
            subscription you may visit your Patreon account page to cancel and
            stop all future payments. You will still have access to the
            community until the month you paid for is over. If you have any
            questions please reach out to us at support@paradiseinvesting.io
          </AccordionPanel>
        </AccordionItem> */}

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="medium">
                Do you guarantee profits for members?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} maxWidth="3xl">
            No. Paradise Investing does not promise or guarantee any amount of
            profits from trading or investing in the stock market. All decisions
            are to made by you as we are not finanical advisors. The focus of
            the community is to help each other learn and get a better
            understanding of the stock market.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="medium">
                I have a question not listed, who do I talk to?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} maxWidth="3xl">
            For any additional assistance or questions, please contact us at
            laviantllc@gmail.com
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default FAQPatreon;
