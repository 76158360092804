import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ChakraProvider, theme } from '@chakra-ui/react';

import { LandingPage } from './pages/LandingPage';
import { DisclaimerPage } from './pages/DisclaimerPage';
import { AccountPage } from './pages/AccountPage';
import { LoginPage } from 'pages/LoginPage';
import ReactGA from 'react-ga';

export const App = () => {
  React.useEffect(() => {
    ReactGA.initialize(`${process.env.REACT_APP_GA}`);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Switch>
          <Route path='/' component={() => <LandingPage />} exact />
          <Route path='/login' component={() => <LoginPage />} exact />
          <Route path='/account' component={() => <AccountPage />} exact />
          <Route
            path='/disclaimer'
            component={() => <DisclaimerPage />}
            exact
          />
        </Switch>
      </Router>
    </ChakraProvider>
  );
};
