import {
  Box,
  Flex,
  useColorModeValue as mode,
  VisuallyHidden,
} from '@chakra-ui/react';
import * as React from 'react';
import { Logo } from './Logo';
import { Link } from 'react-router-dom';

export const NavbarAccount = () => {
  return (
    <Box>
      <Box as='header' bg={mode('white', 'gray.800')} borderBottomWidth='1px'>
        <Box maxW='7xl' mx='auto' py='4' px={{ base: '6', md: '8' }}>
          <Flex as='nav' justify='space-between'>
            <Box>
              <VisuallyHidden>Paradise Investing</VisuallyHidden>
              <Link to='/'>
                <Logo h='6' iconColor='blue.500' />
              </Link>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};
